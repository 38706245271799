import {Group, Box, Loader, LoadingOverlay, Stack, Text, TextInput, Textarea} from '@mantine/core';
import { useForm } from 'react-hook-form';
import FormNavigation from 'components/Modals/RoleInfo/FormNavigation';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useEffect, useState } from 'react';
import RolebotTextInput from 'components/Inputs/RolebotTextInput';

interface StepOneProps {
  next: (payload: object) => void;
  save: (payload: object) => void;
  values: { first_profile_link: string; second_profile_link: string, ideal_talent_expectations: string, job_description: string };
  isFetching: boolean;
  isSubmitting: boolean;
  showFinishLater: boolean;
  needsUpdate: boolean;
  isLoading: boolean;
}

const StepOneSchema = z
  .object({
    first_profile_link: z
      .string()
      .max(255, 'Must be under 255 chars')
      .regex(/^(https?):\/\/[^\s$.?#].[^\s,*!@^{}\[\];'"]*$/gm, 'Must be a valid URL')
      .regex(
        /^(?!https:\/\/www\.linkedin\.com\/talent\/profile\/).+$/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe".'
      )
      .regex(
        /^(?!https:\/\/(drive\.google\.com|docs\.google\.com\/document\/u\/0\/\?tgif=d|docs\.google\.com\/document\/d\/.*)).+/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe". Google Drive is not supported at this time'
      )
      .optional()
      .or(z.literal('')),
    second_profile_link: z
      .string()
      .max(255, 'Must be under 255 chars')
      .regex(/^(https?):\/\/[^\s$.?#].[^\s,*!@^{}\[\];'"]*$/gm, 'Must be a valid URL')
      .regex(
        /^(?!https:\/\/www\.linkedin\.com\/talent\/profile\/).+$/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe".'
      )
      .regex(
        /^(?!https:\/\/(drive\.google\.com|docs\.google\.com\/document\/u\/0\/\?tgif=d|docs\.google\.com\/document\/d\/.*)).+/,
        'Must be a public URL e.g. "https://www.linkedin.com/johndoe". Google Drive is not supported at this time'
      )
      .optional()
      .or(z.literal('')),
    ideal_talent_expectations: z //what is this?
        .string()
        .max(500, 'Must be under 500 chars')
        .optional()
        .or(z.literal('')),

    job_description: z
      .string()
      .max(15000, 'Job Description too long')
      .regex(
        /^(?!((https?:\/\/|www\.)[^\s]+)$)/,
        'Job description cannot be a URL, please enter a text description.'
      )
      .optional()
      .or(z.literal('')),
  })
  .refine(
    (data) => {
      // Only validate if both links are provided and not empty
      if (data.first_profile_link && data.second_profile_link) {
        return data.first_profile_link !== data.second_profile_link;
      }
      // Skip validation if either link is empty
      return true;
    }, 
    {
      message: "Links can't be the same",
      path: ['second_profile_link'],
    }
  );

const InitialDataStep = ({ next, save, values, isFetching, isSubmitting, showFinishLater, needsUpdate, isLoading }: StepOneProps) => {
  const { first_profile_link, second_profile_link, ideal_talent_expectations, job_description } = values;
  const [firstRun, setFirstRun] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      first_profile_link: first_profile_link || '',
      second_profile_link: second_profile_link || '',
      ideal_talent_expectations: ideal_talent_expectations || '',
      job_description: job_description || ''
    },
    resolver: zodResolver(StepOneSchema),
  });

  const [firstProfileLinkWatcher, secondProfileLinkWatcher, jobDescriptionWatcher] =
      watch(['first_profile_link', 'second_profile_link', 'job_description']);

  //In some instances the role info data could be loading and we would need to reset the form to get new values
  useEffect(() => {
    if (firstRun) {
      setFirstRun(false);
      return;
    }

    reset({
      first_profile_link: first_profile_link || '',
      second_profile_link: second_profile_link || '',
      ideal_talent_expectations: ideal_talent_expectations || '',
      job_description: job_description || ''
    });
    // eslint-disable-next-line
  }, [first_profile_link, second_profile_link, job_description]);

  useEffect(() => {
    if (errors?.second_profile_link?.message === "Link's can't be the same") {
      clearErrors('second_profile_link');
    }

    if(firstProfileLinkWatcher !== "" && secondProfileLinkWatcher !== "" && firstProfileLinkWatcher === secondProfileLinkWatcher) {
      errors.second_profile_link = {
        type: 'manual',
        message: "Links can't be the same",
      };
    }
  }, [firstProfileLinkWatcher, secondProfileLinkWatcher, errors, clearErrors]);

  // Add this effect to trigger validation whenever any of the watched fields change
  useEffect(() => {
    if (!firstRun && (firstProfileLinkWatcher || secondProfileLinkWatcher || jobDescriptionWatcher)) {
      trigger();
    }
  }, [firstProfileLinkWatcher, secondProfileLinkWatcher, jobDescriptionWatcher, trigger, firstRun]);

  const firstProfileLinkLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>Link to first ideal candidate</Text>
    </Group>
  );

  const secondProfileLinkLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>Link to second ideal candidate</Text>
    </Group>
  );

  const jobDescriptionLabel = (
    <Group noWrap spacing={'xs'} align={'center'}>
      <Text weight={400}>Job Description</Text>
    </Group>
  );

  return (
    <>
      <Box mb={20}>
        <LoadingOverlay visible={isFetching} loader={<Loader size={'xl'} variant={'bars'} color={'#6852DB'} />} />
        <Stack spacing={0}>
          <Text size={'md'} mb={20} color={'#242424'}>
            Let's get the search started!
          </Text>
          <Text mb={20} color={'#242424'}>
            Provide 1 or 2 URLs of your ideal candidates, e.g. someone that already works at your company or a job description text.
          </Text>
          <Stack>
            <RolebotTextInput
              placeholder={'https://www.linkedin.com/johndoe '}
              label={firstProfileLinkLabel}
              disabled={!showFinishLater || needsUpdate}
              {...register('first_profile_link', {
                setValueAs: (value) => value.replace(/\/+$/, ''),
              })}
              error={errors?.first_profile_link?.message}
            />
            <RolebotTextInput
              placeholder={'https://www.janedoe.com '}
              label={secondProfileLinkLabel}
              disabled={!showFinishLater || needsUpdate}
              {...register('second_profile_link', {
                setValueAs: (value) => value.replace(/\/+$/, ''),
              })}
              error={errors?.second_profile_link?.message}
            />
            <Textarea
              placeholder={'Enter the job description here'}
              label={jobDescriptionLabel}
              disabled={!showFinishLater || needsUpdate}
              minRows={4}
              autosize
              maxRows={10}
              value={watch('job_description')}
              {...register('job_description')}
              error={errors?.job_description?.message}
              styles={{
                error: {
                  fontSize: '1rem',
                  fontWeight: 500
                }
              }}
            />
          </Stack>
        </Stack>
      </Box>
      <div style={{marginBottom: '2px'}}>
      <FormNavigation
        step={1}
        next={handleSubmit(next)}
        save={handleSubmit(save)}
        isSubmitting={isSubmitting}
        isSaveEnabled={!isValid}
        showFinishLater={showFinishLater}
        isNextDisabled={
          (firstProfileLinkWatcher === '' && secondProfileLinkWatcher === '' && jobDescriptionWatcher === '') || 
          !isValid || 
          isLoading
        }
      />
      </div>
    </>
  );
};

export default InitialDataStep;
