import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useGetUserQuery } from 'app/services/rolebot';

const IntercomProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: userData = { user: null } } = useGetUserQuery();

  useEffect(() => {
    if (userData.user) {
      Intercom({
        app_id: process.env.REACT_APP_INTERCOM_APP_ID || '',
        user_id: userData.user.id.toString(),
        name: userData.user.name,
        email: userData.user.email,
        created_at: new Date(userData.user.created_at).getTime() / 1000,
        user_hash: userData.user.intercom_hash
      });
    }
  }, [userData]);

  return <>{children}</>;
};

export default IntercomProvider; 