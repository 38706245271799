import { Stack, Text, Title, Image, createStyles } from '@mantine/core';
import RolebotButton from 'components/public/Buttons/RolebotButton';
import React from 'react';

const useStyles = createStyles((theme) => ({
    image: {
        height: 250,
        width: 'auto%',
    },

    noItemsTitle: {
        color: '#242424',
        fontSize: 28,
        [theme.fn.largerThan('md')]: {
            fontSize: 28,
        },
    },

    placeholderContainer: {
        paddingInline: 25,
        gap: 10,
        [theme.fn.largerThan('md')]: {
            paddingInline: 0,
            gap: 0,
        },
    },
}))

interface FeedbackTalentNotFoundProps {
    onAdjustSearchClick: () => void;
}

const FeedbackTalentNotFound = ({onAdjustSearchClick} : FeedbackTalentNotFoundProps) => {
    const { classes } = useStyles();

    return (
        <Stack sx={{ height: '100%' }} align={'center'} mb={'10%'} justify={'center'} spacing={20}>
            <Image src={require('../../../assets/img/RB_ashamed.png')} fit={'contain'} width={140} mt={20}/>
            <Title className={classes.noItemsTitle} sx={{ color: '#242424' }}>
                We couldn't find any matches for your search
            </Title>
            <Stack align={'center'} justify={'center'} className={classes.placeholderContainer}>
                <Text color={'#242424'} align={'center'}>
                We’re continually adding new talent to our database, so please check back soon or come back tomorrow for more results. We’ll also let you know once we find matches that fit your criteria—thank you for your patience.
                </Text>
                <RolebotButton
                    onClick={onAdjustSearchClick}
                    sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '30px', maxWidth: 'fit-content' }}
                >
                    Adjust Search Criteria
                </RolebotButton>
            </Stack>
        </Stack>
    );

}

export default FeedbackTalentNotFound;