import React from 'react';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { Provider } from 'react-redux';
import store, { history } from 'app/store';
import AppRouter from 'router';
import theme from 'theme';
import RoleInfo from 'components/Modals/RoleInfo/RoleInfo';
import RoleAdmins from 'components/Modals/RoleAdmins/RoleAdmins';
import RoleSetup from 'components/Modals/RoleSetup';
import LaunchRole from 'components/Modals/LaunchRole/LaunchRole';
import PauseRole from 'components/Modals/RoleSettings/PauseRole';
import CloseRole from 'components/Modals/RoleSettings/CloseRole';
import OpenRole from './components/Modals/RoleSettings/OpenRole';
import RoleUsers from 'components/Modals/RoleSettings/RoleUsers';
import VideoTutorial from 'components/Modals/VideoTutorial/VideoTutorial';
import ProvideFeedback from 'components/Modals/ProvideFeedback/ProvideFeedback';
// import ImproveResults from 'components/Modals/ImproveResults/ImproveResults';
import PaymentMethod from 'components/Modals/PaymentMethod/PaymentMethod';
import ResumeRole from 'components/Modals/RoleSettings/ResumeRole';
import UpdatePayments from 'components/Modals/UpdatePayments/UpdatePayments';
import ThemeStyles from 'theme/themeProvider';
import AddUserToRoles from 'components/Modals/AddUserToRoles/AddUserToRoles';
import DeactivateUser from 'components/Modals/DeactivateUser/DeactivateUser';
import InviteUser from 'components/Modals/InviteUser/InviteUser';
import ContactSupport from 'components/Modals/ContactSupport/ContactSupport';
import GracePeriodExpired from 'components/Modals/GracePeriodExpired';
import DisconnectIntegration from 'components/Modals/DisconnectIntegration/DisconnectIntegration';
import LeverAuthExpired from 'components/Modals/LeverAuthExpired/LeverAuthExpired';
import CronofyAuthExpired from 'components/Modals/CronofyAuthExpired/CronofyAuthExpired';
import CronofyMissingSetupModal from 'components/Modals/CronofyMissingSetupModal/CronofyMissingSetupModal';
import CsrfToken from './components/CsrfToken/CsrfToken';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';
import CronofyModal from 'components/Modals/CronofyModal/CronofyModal';
import Hotjar from '@hotjar/browser';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toastify-messages.css';
import './styles/recharts.css';
import './styles/global.css';
import { ToastContainer } from 'react-toastify';
import ConfettiAnimation from 'components/ConfettiAnimation/ConfettiAnimation';
import NewOffice from 'components/Modals/NewOffice/NewOffice';
import Announcements from 'components/Modals/Announcements/Announcements';
import RoleLimitReached from 'components/Modals/RoleLimit/RoleLimitReached';
import InactivePlan from 'components/Modals/InactivePlan/InactivePlan';
import LinkToATS from 'components/Modals/LinkToATS/LinkToATS';
import ContactSales from 'components/Modals/ContactSales/ContactSales';
import CancelPlanRenewal from 'components/Modals/CancelPlanRenewal/CancelPlanRenewal';
import userflow from 'userflow.js'
import DisableMFA from 'components/Modals/DisableMFA/DisableMFA';
import EnableMFA from 'components/Modals/EnableMFA/EnableMFA';
import RequireReactivation from 'components/Modals/RequireReactivation/RequireReactivation';
import ReactivationFormModal from 'components/Modals/ReactivationFormModal/ReactivationFormModal';
import UpgradePlan from 'components/Modals/UpgradePlan/UpgradePlan';
import ConfirmNewPlan from 'components/Modals/ConfirmNewPlan/ConfirmNewPlan';
import PricingPlanUpdated from 'components/Modals/PricingPlanUpdated/PricingPlanUpdated';
import AutopilotSetUp from 'components/Modals/AutopilotSetUp/AutopilotSetUp';
import IntercomProvider from 'components/IntercomProvider/IntercomProvider';

const localizedFormat = require('dayjs/plugin/localizedFormat');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(localizedFormat);

ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
Hotjar.init(Number(process.env.REACT_APP_HOTJAR_ID), Number(process.env.REACT_APP_HOTJA_VERSION));

userflow.init(`${process.env.REACT_APP_USERFLOW_TOKEN}`)


function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <MantineProvider withNormalizeCSS withGlobalStyles theme={theme} styles={ThemeStyles}>
          <IntercomProvider>
            <CsrfToken>
              <NotificationsProvider position='top-right'>
                <ConfettiAnimation />
                <ModalsProvider
                  modals={{
                    roleSetup: RoleSetup,
                    roleInfo: RoleInfo,
                    manageAdmins: RoleAdmins,
                    launchRole: LaunchRole,
                    resumeRole: ResumeRole,
                    pauseRole: PauseRole,
                    closeRole: CloseRole,
                    openRole: OpenRole,
                    roleUsers: RoleUsers,
                    videoTutorial: VideoTutorial,
                    // improveResults: ImproveResults,
                    paymentMethod: PaymentMethod,
                    updatePayment: UpdatePayments,
                    addUserToRoles: AddUserToRoles,
                    deactivateUser: DeactivateUser,
                    inviteUser: InviteUser,
                    contactSupport: ContactSupport,
                    gracePeriodExpired: GracePeriodExpired,
                    disconnectIntegration: DisconnectIntegration,
                    cronofyAvailabilityRuler: CronofyModal,
                    cronofyAuthExpiredModal: CronofyAuthExpired,
                    leverAuthExpiredModal: LeverAuthExpired,
                    cronofyMissingSetupModal: CronofyMissingSetupModal,
                    newOffice: NewOffice,
                    announcements: Announcements,
                    roleLimit: RoleLimitReached,
                    linkToATS: LinkToATS,
                    contactSales: ContactSales,
                    cancelPlanRenewal: CancelPlanRenewal,
                    inactivePlan: InactivePlan,
                    disableMFA: DisableMFA,
                    enableMFA: EnableMFA,
                    requiresReactivationModal: RequireReactivation,
                    reactivationFormModal: ReactivationFormModal,
                    confirmNewPlan: ConfirmNewPlan,
                    provideFeedback: ProvideFeedback,
                    upgradePlan: UpgradePlan,
                    pricingPlanUpgradeModal: PricingPlanUpdated,
                    autopilotSetUp: AutopilotSetUp
                  }}
                  modalProps={{
                    withinPortal: true,
                    size: 'xl',
                    centered: true,
                    withCloseButton: false,
                  }}
                >
                  <AppRouter />
                </ModalsProvider>
              </NotificationsProvider>
              <ToastContainer />
            </CsrfToken>
          </IntercomProvider>
        </MantineProvider>
      </Router>
    </Provider>
  );
}

export default App;
