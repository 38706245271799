import { useReducer } from 'react';
import { Group, Stack, Transition } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import RolebotButton from 'components/public/Buttons/RolebotButton';

interface FreemiumUpgradeProps {
  onModalClose: () => void;
  openModal: () => void;
}

const FreemiumUpgrade = ({ onModalClose, openModal }: FreemiumUpgradeProps) => {

  const [open, close] = useReducer(() => false, true);


  return (
    <Transition transition={'slide-down'} mounted={open}>
      {(styles) => (
        <div style={{ position: 'relative' }}>
        <div
          style={{
            ...styles,
            backgroundColor: '#F4DCFF',
            padding: '20px',
          }}
        >
          <Group sx={{ justifyContent: 'center', position: 'relative' }} align={'center'} px={10} spacing={0} noWrap>
            <Stack align="stretch" justify="center">
              <Group >
                  <RolebotButton
                    onClick={openModal}
                    type={'secondary'}
                  >
                    Upgrade your plan now
                  </RolebotButton>
              </Group>
            </Stack>

            <FontAwesomeIcon
              icon={faXmark}
              style={{ position: 'absolute', right: '0px', top: '0px' }}
              onClick={close}
              color={'#242424'}
              width={20}
            />
          </Group>
        </div>
        </div>
      )}
    </Transition>
  );
};
export default FreemiumUpgrade;
