import { Text, Container, Title, Stack, Image, createStyles, Button, Group } from '@mantine/core';
import RolebotPet from '../../assets/img/rolebot-pet.svg';


const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },

  title: {
    color: '#242424',
    fontSize: '24px',
    fontWeight: 600,
  },
  text: {
    color: '#242424',
    maxWidth: '400px',
  },

  buttonGroup: {
    marginTop: 20,
  },
}));

export function FreemiumPendingApproval() {
  const { classes } = useStyles();

  const handleCheckStatus = () => {
    window.location.reload();
  };

  return (
    <Container size="md" className={classes.container}>
      <Stack align="center" spacing={0} mb="20%">
        <Image src={RolebotPet} width={200} />
        <Title my={20} className={classes.title}>
          Freemium Account Pending Approval
        </Title>
        <Stack spacing={10} align="center">
          <Text className={classes.text} align="center">
            Your freemium account is under review! 🚀
          </Text>
          <Text className={classes.text} align="center">
            Hang tight – our team is checking everything, and you'll be in soon. We'll notify you as soon as you're approved!
          </Text>
        </Stack>
        <Group className={classes.buttonGroup}>
          <Button onClick={handleCheckStatus}>
            Refresh the page
          </Button>
        </Group>
      </Stack>
    </Container>
  );
}
