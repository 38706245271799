import { ContextModalProps } from "@mantine/modals"
import { Text, LoadingOverlay } from "@mantine/core"
import { UpgradePlanProps } from "types/modals"
import { createStyles } from "@mantine/core";
import { useModals } from '@mantine/modals';
import TitleWithClose from "../components/TitleWithClose";
import { useState, useMemo } from "react";
import { useGetShopifyProductsQuery, useGetCompanyQuery } from "app/services/rolebot";
import RolebotPlan from "./RolebotPlan";
import BillingCycleToggle from "./BillingCycleToggle";

const useStyles = createStyles((theme) => ({
    container: {
        padding: '20px',
        background: 'linear-gradient(180deg, #48309D 26%, #24154F 100%)',
        paddingBottom: '60px',
        height: '100%',
        minHeight: '890px',        

        [`@media (min-width: 500px)`]: {
            minHeight: '800px',
        },

    },
    plansContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '40px',
    },

}));

const UpgradePlan = ({ id, context, innerProps: { onclose } }: ContextModalProps<UpgradePlanProps>) => {
    const { classes, cx } = useStyles();

    const modals = useModals();
    const { data: shopifyProducts, isFetching } = useGetShopifyProductsQuery({});
    const { data: company } = useGetCompanyQuery();

    const handleClose = () => {
        onclose();
        modals.closeModal(id);
    }

    const activeRoleLimit = useMemo(() => {
        return company?.active_role_limit;
    }, [company]);

    const activeRoleCount = useMemo(() => {
        return company?.active_role_count;
    }, [company]);
    
    const subscriptionType = useMemo(() => {
        return company?.customer_pricing_plan?.subscription_type;
    }, [company]);

    const [billingCycle, setBillingCycle] = useState(() => 
        subscriptionType === 'Annual' ? 'yearly' : 'monthly'
    );

    const handleBillingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingCycle(event.target.value);
    };

    const extractShopifyId = (gid: string): string => {
        return gid.split('/').pop() || '';
    };

    const handlePlanSelected = (plan: any) => {
        const sellingPlanId = extractShopifyId(plan.sellingPlanId);
        const url = process.env.REACT_APP_SHOPIFY_SELECT_PLAN_URL + `/pages/redirect-upgrade-freemium-logic?quantity=${plan.activeRoleLimit}&selling_plan_id=${sellingPlanId}`;
        
        handleClose();

        window.open(url, '_blank');
    };

    const handleContactSales = () => {
        // contactSalesModal({ fromProPlan: true });
        const url = process.env.REACT_APP_SHOPIFY_SELECT_PLAN_URL + `/pages/contact-sales`;
        window.open(url, '_blank');
        handleClose();
    };

    return (
        <>
            <div className={!isFetching ? classes.container : ''}>
                <LoadingOverlay visible={isFetching} />
                {!isFetching && (
                    <>
                        <TitleWithClose id={id} title={''} mb={4} onClose={handleClose} />
                        <Text align="center" mb={20} style={{ fontFamily: 'Helvetica, sans-serif', fontSize: '40px', fontWeight: 400, color: '#FFFFFF' }}>
                            Upgrade your plan now!
                        </Text>

                        <BillingCycleToggle
                            billingCycle={billingCycle}
                            handleBillingChange={handleBillingChange}
                            subscriptionType={subscriptionType}
                        />
                        <div className={classes.plansContainer}>

                            <RolebotPlan
                                planType='freemium'
                                key="freemium-plan"
                                product={{ title: 'Freemium' }}
                                isPro={false}
                                billingCycle={billingCycle}
                                handlePlanSelected={handlePlanSelected}
                                handleContactSales={handleContactSales}
                                activeRoleLimit={activeRoleLimit}
                                activeRoleCount={activeRoleCount}
                            />

                            {shopifyProducts?.data && shopifyProducts.data.map((product: any) => (
                                <RolebotPlan
                                    planType='lite'
                                    key={product.id}
                                    product={product}
                                    isPro={false}
                                    billingCycle={billingCycle}
                                    handlePlanSelected={handlePlanSelected}
                                    handleContactSales={handleContactSales}
                                    activeRoleLimit={activeRoleLimit}
                                    activeRoleCount={activeRoleCount}
                                />
                            ))}


                            <RolebotPlan
                                planType='pro'
                                key="pro-plan"
                                product={{ title: 'Pro' }}
                                isPro={true}
                                billingCycle={billingCycle}
                                handlePlanSelected={handlePlanSelected}
                                handleContactSales={handleContactSales}
                                activeRoleLimit={activeRoleLimit}
                                activeRoleCount={activeRoleCount}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default UpgradePlan
