import { Box, Stack, Text, Title, Image, Button } from "@mantine/core";
import RolebotSelect from "components/RolebotSelect/RolebotSelect";
import { useState, useEffect } from "react";
import ProPlan from 'assets/img/pro-plan.png';
import { ChevronDown } from "tabler-icons-react";
import RolebotButton from 'components/public/Buttons/RolebotButton';
import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme, planType: 'freemium' | 'lite' | 'pro') => ({
    planCardContainer: {
        display: planType !== 'lite' ? 'none' : 'flex',
        flex: '1 1 330px',  
        alignItems: 'center',
        justifyContent: 'center',

        maxWidth: '330px',   
        minWidth: '260px',

        borderRadius: '10px',
        padding: '40px 20px',

        [`@media (min-width: 992px)`]: {
            display: 'flex',
        },
    },

}));
interface RolebotPlanProps {
    product: any;
    isPro: boolean;
    activeRoleLimit: number | undefined;
    activeRoleCount: number | undefined;
    billingCycle: string;
    planType: 'freemium' | 'lite' | 'pro' ;
    handlePlanSelected: (plan: any) => void;
    handleContactSales: () => void;
}

const RolebotPlan = ({ planType, product, isPro, activeRoleLimit, activeRoleCount, billingCycle, handlePlanSelected, handleContactSales }: RolebotPlanProps) => {
    const [roleNumber, setRoleNumber] = useState([
        { value: "1", label: "1 role" },
        { value: "2", label: "2 roles" },
        { value: "3", label: "3 roles" },
        { value: "4", label: "4 roles" },
        { value: "5", label: "5 roles" }
    ]);

    const [selectedRoleNumber, setSelectedRoleNumber] = useState(1);
    const [productPrice, setProductPrice] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleSelectedRoleNumber = (value: string) => {
        setSelectedRoleNumber(parseInt(value, 10));
    };

    const handleSelectAndContinue = () => {
        const payload = {
            productId: product.id,
            productPrice: productPrice,
            sellingPlanId: selectedPlan,
            activeRoleLimit: selectedRoleNumber
        }

        handlePlanSelected(payload);
    }

    useEffect(() => {
        if (isPro) {
            setProductPrice(0);
        } else {
            const sellingPlanGroups = product.selling_plan_groups;

            if (sellingPlanGroups && sellingPlanGroups.length > 0) {
                const monthlyPlanGroup = sellingPlanGroups.find((group: any) => group.name === 'Monthly Subscription');
                const yearlyPlanGroup = sellingPlanGroups.find((group: any) => group.name === 'Yearly Subscription');

                let selectedPlan;
                if (billingCycle === 'yearly' && yearlyPlanGroup) {
                    selectedPlan = yearlyPlanGroup.selling_plans[0];
                } else if (monthlyPlanGroup) {
                    selectedPlan = monthlyPlanGroup.selling_plans[0];
                }

                setSelectedPlan(selectedPlan?.id);

                if (selectedPlan) {
                    const adjustmentValue = selectedPlan.pricing_policies[0].adjustment_value;
                    const adjustmentType = selectedPlan.pricing_policies[0].adjustment_type;
                    
                    let price = parseFloat(product.price) / 100;
                    if (adjustmentType === 'PERCENTAGE') {
                        price = price * (1 - adjustmentValue / 100);
                    }
                    
                    setProductPrice(Math.round(price));
                } else {
                    setProductPrice(Math.round(parseFloat(product.price) / 100));
                }
            } else {
                setProductPrice(Math.round(parseFloat(product.price) / 100));
            }
        }
    }, [billingCycle, product.price, isPro, product.selling_plan_groups]);


    const getFirstSection = () => {
        if(planType === 'pro') {
            return <Text
            style={{
                marginTop: '5px',
                marginBottom: '5px',
                fontSize: '16px',
                color: '#242424',
                fontWeight: 400
            }}
        >
            6+ roles
        </Text>
        }

        if(planType === 'lite') {
            return <RolebotSelect
            rightSection={<ChevronDown size={20} />}
            data={roleNumber}
            value={selectedRoleNumber.toString()}
            onChange={handleSelectedRoleNumber}
            size='sm'
        />
        }

        return null;
    }

    const getSecondSection = () => {
        if(planType === 'pro') {
            return <Image src={ProPlan} alt={'ProPlan'} fit={'contain'} height={40} pt={10} />

        }

        if (planType === 'lite') {
            return  <Text
            style={{
                fontSize: '35px',
                color: '#242424'
            }}
        >
            ${productPrice * selectedRoleNumber}<span style={{ fontSize: '18px' }}> / month</span>
        </Text>
        }

        return <Text style={{ minHeight: '110px', alignContent: 'center' }}>No Credit Card!</Text>
    }

    const getPlanIncludesSection = () => {
        if(planType === 'pro') {
            return <div>
            <div style={{ textAlign: 'left', lineHeight: '1.7', fontSize: '12px', marginTop: '20px'  }}>
                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424'}}>
                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>15 daily candidates per role
                </p>
                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424'}}>
                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>As many roles as needed
                </p>
                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Specialized customer support agent
                </p>
                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Interchangeable roles
                </p>
                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Ability lo launch sourcing-only roles
                </p>
                <p style={{ lineHeight: '1.7', margin: '0', color: '#242424' }}>
                    <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>Live support
                </p>
            </div>
        </div>
        }
        if(planType === 'lite') {
            return <div
            dangerouslySetInnerHTML={{ __html: product.description || '' }}
        />
        }

        return <div className="rolebot-plans-panel__features" style={{ textAlign: 'left', fontSize: '12px', marginTop: '20px' }}>
            <p style={{ lineHeight: 1.7, margin: 0, color: '#242424' }}>
                <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>
                5 daily candidates per role
            </p>
            <p style={{ lineHeight: 1.7, margin: 0, color: '#242424' }}>
                <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>
                1 role
            </p>
            <p style={{ lineHeight: 1.7, margin: 0, color: '#242424' }}>
                <span style={{ color: '#6fbe44', marginRight: '10px' }}>✔</span>
                Access to candidate's contact info
            </p>
        </div>
    }

    const getButton = () => {
        if(planType === 'pro') {
            return  <RolebotButton
            onClick={handleContactSales}
            type={'secondary'}
            mt="xl"
        >
            Contact Sales
        </RolebotButton>
        }

        if(planType === 'lite') {
            return <Button
            mt="xl"
            px={20}
            tabIndex={3}
            onClick={handleSelectAndContinue}
            disabled={false}
        >
            Select and continue
        </Button>
        }

        return <Button
        mt="xl"
        px={20}
        tabIndex={3}
        disabled={true}
    >
        This is your current plan
    </Button>
    }

    const { classes } = useStyles(planType);

    return (
        <>
            <Box
                className={classes.planCardContainer}
                sx={{
                    backgroundColor: isPro ? '#FFFFFF' : '#FDF8FF',
                    border: isPro ? '2px solid #3A83F6' : '2px solid #7039ED',
                }}
            >
                <Stack align={'center'} style={{ minHeight: '450px' }}>
                    <Title order={2}
                        style={{
                            fontSize: '22px',
                            fontWeight: 500,
                            marginBottom: '10px'
                        }}
                    >
                        {product.title}
                    </Title>


                    {getFirstSection()}

                    {getSecondSection()}
                   
                    <Text
                        style={{
                            fontSize: '12px',
                            fontWeight: 600,
                            color: '#242424',
                            fontFamily: 'Roboto',
                            marginTop: '10px',
                        }}
                    >
                        {isPro ? 'Everything in Lite, plus' : product.title + ' plan includes'}
                    </Text>


                    {getPlanIncludesSection()}


                    <Box style={{ marginTop: 'auto' }}>
                        {getButton()}
                    </Box>


                </Stack>
            </Box>
        </>
    )
}

export default RolebotPlan;
