import React from 'react';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';
import { Select, SelectProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const RolebotSelect = ({ onDropdownClose, onDropdownOpen, ...rest }: SelectProps) => {
  const [opened, handlers] = useDisclosure(true);

  return (
    <Select
      {...rest}
      onDropdownClose={() => {
        onDropdownClose && onDropdownClose();
        handlers.open();
      }}
      onDropdownOpen={() => {
        onDropdownOpen && onDropdownOpen();
        handlers.close();
      }}
      rightSection={opened ? <ChevronDown color={'#B3B3B3'} /> : <ChevronUp color={'#B3B3B3'} />}
      styles={{ rightSection: { pointerEvents: 'none' } }}
    />
  );
};

export default RolebotSelect;
