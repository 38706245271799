import { useLocation } from 'react-router-dom';
import { useGetCompanyQuery, useGetUserQuery } from 'app/services/rolebot';
import { isFreemiumPlan } from 'utils';
import { useMemo } from 'react';

interface FreemiumCheck {
  isLoading: boolean;
  isFreemium: boolean | null;
  isFreemiumOnHold: boolean | null;
}

export const useFreemiumCheck = (): FreemiumCheck => {
  const currentPath = useLocation().pathname;
  const isPublicRoute = currentPath === '/';

  const { 
    data: company, 
    isLoading: isCompanyLoading, 
    error: companyError
  } = useGetCompanyQuery(undefined, {
    skip: isPublicRoute
  });

  const {
    data: user,
    isLoading: isUserLoading,
    error: userError
  } = useGetUserQuery(undefined, {
    skip: isPublicRoute
  });


const state = useMemo(() => {
    const isLoading = (isCompanyLoading || isUserLoading) && !companyError && !userError;
    
    const isFreemium = !companyError && company ? isFreemiumPlan(company) : null;
    
    let isFreemiumOnHold = null;

  if (!userError && user && user.user.freemium_onhold !== undefined) {
      isFreemiumOnHold = user.user.freemium_onhold;
  }

    return {
      isLoading,
      isFreemium,
      isFreemiumOnHold
    };
  }, [
    company, 
    user, 
    isCompanyLoading, 
    isUserLoading, 
    companyError, 
    userError
  ]);

  return state;
}; 
