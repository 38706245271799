import { createSlice } from '@reduxjs/toolkit';
import { rolebotApi } from '../../app/services/rolebot';
import userflow from 'userflow.js'

interface AuthState {
  isLoggedIn: boolean;
  userId: number | null;
  OTPToken: string | null;

}

const initialState: AuthState = {
  isLoggedIn: false,
  userId: null,
  OTPToken: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(rolebotApi.endpoints.signIn.matchFulfilled, (state, action) => {
      console.log(action.payload)
      if (action.payload.require_otp) {
        state.OTPToken = action.payload.token || null
      } else {
        state.isLoggedIn = true;
      }
    });
    builder.addMatcher(rolebotApi.endpoints.getUser.matchFulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.userId = action.payload.user.id;

      //when we fulfill the sign in, we declare the userflow object
      userflow.identify(`${action.payload.user.id}`, {
        name: `${action.payload.user.name}`,
        email: `${action.payload.user.email}`,
        signed_up_at: `${action.payload.user.created_at}`,
        type_of_user: `${action.payload.customer.customer_info.is_admin ? 'Admin' : 'Member'}`,
        freemium_onhold: `${Boolean(action.payload.user.freemium_onhold)}`
      })

      userflow.group(`${action.payload.customer.id}`, {
        name: `${action.payload.customer.name}`,
        signed_up_at: `${action.payload.customer.created_at}`,
        type_of_plan: `${action.payload.customer.role_type.name}`
      })
      
    });
    builder.addMatcher(rolebotApi.endpoints.signOut.matchFulfilled, (state) => {
      return initialState;
    });
    builder.addMatcher(rolebotApi.endpoints.postVerifyOTP.matchFulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.OTPToken = null;
      if( action.payload.otp_session_uuid ){
        let otsCookie = document.cookie.match('(^|;)\\s*' + 'ots' + '\\s*=\\s*([^;]+)')?.pop() || null;
        if (otsCookie) {
          otsCookie += `,${action.payload.otp_session_uuid}`;
        } else {
          otsCookie = action.payload.otp_session_uuid;
        }
        document.cookie = `ots=${otsCookie}`;
      }
    });
    builder.addMatcher(rolebotApi.endpoints.postResendOTP.matchFulfilled, (state, action) => {
      if (action.payload.success) {
        state.OTPToken = action.payload.token;
      }
    })

  },
});

const { reducer, actions } = authSlice;
export const { logOut } = actions;
export default reducer;